import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/principal/App';
import reportWebVitals from './reportWebVitals';


// CSS Propias
import './assets/css/susc.css';
import './assets/css/votar.css';
// import './assets/css/popup.css';
import './assets/scss/styles.css';

// Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';

// traductor
import {I18nextProvider} from 'react-i18next'
import i18next from 'i18next'
//lenguajes
import es from './lang/es-ES.json'
import en from './lang/en-US.json'


  i18next.init({
    interpolation:{ escapeValue: false },
    lng: "es" ,
    resources:{
      es:{
        global: es
      },
      en:{
        global: en
      }
    }
  })


ReactDOM.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
