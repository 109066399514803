import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Global from "../../../../Global";
import axios from "axios";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import PpHourglass from "./../../Asamblea/PopUps/PpHourglass";
import SimpleReactValidator from "simple-react-validator";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import moment from 'moment';
import "moment/locale/es";

import { styled } from "@material-ui/core";
import * as XLSX from 'xlsx'

export default class SubirCartera extends Component {
    state = {
        PpHourglass: false,
        redirect_home: false,
        data: false,
        excelFileBase64: "",
        namefile: "",
        carteraConceptos: [],
        Cartera: [],
        Mora: [],
        vrMora: "",
        c_admon: "",
        Fecha: undefined,
    };

    //VALIDA EL ESTADO
    componentWillMount() {
        this.validator = new SimpleReactValidator({
            messages: {
                required: "El campo es obligatorio.",
                alpha_dash: "El campo es obligatorio.",
                numeric: "Solo se permiten números.",
                min: "La Placa debe tener mínimo 5 caracteres.",
            },
        });
        this.InitCartera()
    }

    InitCartera = () => {
        this.setState({ PpHourglass: true });

        var body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}cartera/inicartera`, body).then((res) => {
            if (res.data.message === "") {
                var index = res.data.carteraConceptos.findIndex(item => item.c_admon === true)
                this.setState({ carteraConceptos: res.data.carteraConceptos, vrMora: res.data.vrMora, c_admon: index, Fecha: res.data.fecha }, () => {
                    this.setState({ data: true, PpHourglass: false });
                })

            } else {
                swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        PpHourglass: false,
                    });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({
                    PpHourglass: false,
                });
            });
        });
    }

    handleFileChange = (e) => {
        var mora = this.state.vrMora
        this.setState({ namefile: "" })
        if (mora !== "" && mora !== undefined && mora !== null) {
            this.setState({ PpHourglass: true })
            const file = e.target.files[0];

            if (file) {
                if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
                    this.setState({ PpHourglass: false, inputKey: Date.now() })
                    swal({
                        title: "ATENCIÓN!",
                        text: "Por favor, seleccione un archivo Excel válido.",
                        icon: "info",
                        button: "Aceptar",
                    })
                } else {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const data = event.target.result;
                        const workbook = XLSX.read(data, { type: 'binary' });


                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];


                        const columnAData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false })
                            .map(row => [row[4], row[5], row[6], row[7], row[8], row[9], row[10], row[11], row[12], row[13], row[14], row[15], row[17]]).slice(1);


                        const areAllFieldsNumeric = columnAData.every((row, rowIndex) => {
                            return row.every((cell, cellIndex) => {
                                if (cellIndex === 0) {
                                    return true;
                                }
                                return !isNaN(parseFloat(cell)) && isFinite(cell);
                            });
                        });

                        if (!areAllFieldsNumeric) {
                            this.setState({ PpHourglass: false, inputKey: Date.now() })
                            swal({
                                title: "ATENCIÓN!",
                                text: "Por favor, asegúrese de que todos los campos sean numéricos.",
                                icon: "info",
                                button: "Aceptar",
                            });
                            return;
                        } else {

                            const readerBase64 = new FileReader();
                            readerBase64.onload = (e) => {
                                const base64Data = e.target.result;
                                this.setState({ excelFileBase64: base64Data, inputKey: Date.now() }, () => this.ValidarExcel(columnAData, file.name));
                            };
                            readerBase64.readAsDataURL(file);


                        }


                    };

                    reader.readAsBinaryString(file);
                }
            }
        } else {
            swal({
                title: "ATENCIÓN!",
                text: "Por favor, ingrese Tope de Mora.",
                icon: "info",
                button: "Aceptar",
            })
        }
    };

    ValidarExcel = (columnAData, name) => {
        this.setState({ PpHourglass: false })
        var temp = this.state.carteraConceptos
        var index = temp.findIndex(item => item.c_admon === true)
        var d_nombre = temp[index].dnombre

        const Cartera = []
        const Mora = []

        for (var i = 0; i < columnAData.length; i++) {
            var cartera = {
                valor1: parseInt(columnAData[i][1]),
                valor2: parseInt(columnAData[i][2]),
                valor3: parseInt(columnAData[i][3]),
                valor4: parseInt(columnAData[i][4]),
                valor5: parseInt(columnAData[i][5]),
                valor6: parseInt(columnAData[i][6]),
                valor7: parseInt(columnAData[i][7]),
                valor8: parseInt(columnAData[i][8]),
                valor9: parseInt(columnAData[i][9]),
                valor10: parseInt(columnAData[i][10]),
                anticipo: parseInt(columnAData[i][11]),
                cuota_admon: parseInt(columnAData[i][12]),
                cdpago: columnAData[i][0],
            }

            var keys = Object.keys(cartera);
            var index1 = keys.findIndex(key => key === d_nombre);
            var keyAtIndex = keys[index1];
            var valueAtIndex = cartera[keyAtIndex];
            var CvrMora = valueAtIndex / columnAData[i][12]

            if (CvrMora > this.state.vrMora) {
                var mora = {
                    cdpago: columnAData[i][0]
                }
                Mora.push(mora)
            }
            Cartera.push(cartera)
        }

        this.setState({ namefile: name, Cartera: Cartera, Mora: Mora })
    }

    handleChange = (index) => (event) => {
        var temp = this.state.carteraConceptos
        temp[index].nombre = (event.target.value).toUpperCase()
        this.setState({ carteraConceptos: temp })
    }

    CambiarCadmon = (valor) => {
        var temp = this.state.carteraConceptos
        var index = temp.findIndex(item => item.dnombre === valor);
        temp.forEach(item => { item.c_admon = false })
        temp[index].c_admon = true
        this.setState({ carteraConceptos: temp, c_admon: index })
    }

    Validar = () => {
        if (this.validator.allValid()) {
            this.GuardarConceptos()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    GuardarConceptos = () => {
        this.setState({ PpHourglass: true });

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            vrmora: Buffer.from("" + this.state.vrMora).toString("base64"),
            conceptos: Buffer.from("" + JSON.stringify(this.state.carteraConceptos)).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}cartera/updconcepto`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({ PpHourglass: false });
                this.GuardarMora()
            } else {
                swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        PpHourglass: false,
                    });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({
                    PpHourglass: false,
                });
            });
        });
    }

    GuardarMora = () => {
        this.setState({ PpHourglass: true });

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            morosos: Buffer.from("" + JSON.stringify(this.state.Mora)).toString("base64"),
            datosadicionales: Buffer.from("" + JSON.stringify(this.state.Cartera)).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}cartera/updatecrmr`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({ PpHourglass: false });
                this.GuardarExcel()
            } else {
                swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        PpHourglass: false,
                    });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({
                    PpHourglass: false,
                });
            });
        });
    }

    GuardarExcel = () => {
        this.setState({ PpHourglass: true });

        var body = {
            ipin: Buffer.from("" + Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from("" + Global.Ph.Codigo).toString("base64"),
            filemora: Buffer.from("" + JSON.stringify(this.state.excelFileBase64)).toString("base64"),
        };

        axios.post(`${Global.Ruta.Url}cartera/updatefilemora`, body).then((res) => {
            if (res.data.message === "") {
                this.setState({ PpHourglass: false });
                swal({
                    title: "Felicitaciones",
                    text: "El archivo de cartera ha sido cargado satisfactoriamente y los morosos fueron marcados.",
                    icon: "success",
                    button: "Aceptar",
                }).then(() => {
                    this.props.onHide()
                });
            } else {
                swal({
                    title: "ERROR!",
                    text: res.data.message,
                    icon: "info",
                    button: "Aceptar",
                }).then(() => {
                    this.setState({
                        PpHourglass: false,
                    });
                });
            }
        }).catch((err) => {
            swal({
                title: "ERROR!",
                text: "Por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
            }).then(() => {
                this.setState({
                    PpHourglass: false,
                });
            });
        });
    }

    render() {
        if (this.state.redirect_home === true) {
            return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
        }

        const Input = styled("input")({
            display: "none",
        });


        if (this.state.data) {
            return (
                <div>
                    <Modal show={this.state.PpHourglass} centered backdrop="static" onHide={() => this.setState({ modal: false })}>
                        <PpHourglass />
                    </Modal>
                    <Modal.Header closeButton><p>{`Añadir Cartera`}</p>
                    <p style={{color: 'red', paddingLeft: '10px'}}>{`Última actualización ${moment(this.state.Fecha).format("LLLL")}`}</p></Modal.Header>
                    <Modal.Body>
                        <div className='row row-cols-2'>

                            {this.state.carteraConceptos.map((valores, index) => (
                                <div className='col' key={index}>
                                    <TextField className="mt-1" variant="outlined" label={valores.dnombre} fullWidth inputProps={{ maxLength: 50 }}
                                        value={valores.nombre} onChange={this.handleChange(index)} />
                                    <label className='text-muted  m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message(`${valores.dnombre}`, valores.nombre, 'required', { className: 'text-danger' })}</label>
                                </div>
                            ))}


                            <div className='col'>
                                <TextField className="" variant="outlined" label={"Tope de Mora"} fullWidth inputProps={{ maxLength: 5 }}
                                    value={this.state.vrMora} onChange={(e) => { this.setState({ vrMora: e.target.value }) }} />
                                <label className='text-muted m-0 px-1 txt-helper' style={{ fontSize: "11px" }}>{this.validator.message(`vrMora`, this.state.vrMora, 'required|numeric|min:0,num', { className: 'text-danger' })}</label>
                            </div>

                            <div className="col">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cuota Administración</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.carteraConceptos[this.state.c_admon].dnombre}
                                        label="Cuota Administración"
                                        onChange={(e) => this.CambiarCadmon(e.target.value)}
                                    >
                                        {this.state.carteraConceptos.map((items) => (
                                            <MenuItem value={items.dnombre}>
                                                {items.dnombre.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>


                            <div className='col-12 mt-2'>
                                <div className="col">
                                    <div className="card mx-auto text-center  position-relative border-a-1 border-secondary">
                                        <div className="card-body">
                                            {this.state.namefile === "" ? (
                                                <div className="row gy-2 justify-content-center">
                                                    <div className="col-12 d-none d-lg-block">
                                                        <span className="fs-4 text-center m-0">
                                                            Arrastre y suelte aquí el archivo Excel
                                                        </span>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <span className="spanO"> o </span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <input
                                                            id="drop-area"
                                                            key={this.state.inputKey}
                                                            type="file" accept=".xls, .xlsx"
                                                            className="top-50 start-50 translate-middle"
                                                            onChange={(event) => this.handleFileChange(event)}
                                                        />
                                                        <label htmlFor="contained-button-file">
                                                            <Input
                                                                id="contained-button-file"
                                                                key={this.state.inputKey}
                                                                type="file" accept=".xls, .xlsx"
                                                                onChange={(event) => this.handleFileChange(event)}
                                                            />
                                                            <button
                                                                className="btn btn-primary text-nowrap"
                                                                variant="contained"
                                                                component="span"
                                                            >
                                                                Seleccionar archivo...
                                                            </button>
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='row row-cols-1 d-flex justify-content-center'>
                                                    <span className='fs-5 fw-bold mb-2'>{this.state.namefile}</span>
                                                    <button className='btn btn-success w-25' onClick={() => { this.setState({ namefile: "", selectedFile: null }) }}> <i className="fa-regular fa-file-excel"></i> Cambiar Archivo </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </Modal.Body >
                    <Modal.Footer>
                        <div className="row w-100 justify-content-center">
                            <div className="col-3 d-grid">
                                <button className="btn btn-primary" disabled={this.state.namefile === ""} onClick={() => { this.Validar() }}>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </div >
            );
        }

        return (
            <div>
                <Modal.Header>
                    Procesando Datos
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center flex-column align-items-center text-center">
                        <div className="spinner-grow text-info" role="status" style={{ width: '5rem', height: '5rem' }} />
                        <span className="my-2 ">Espere un momento...</span>
                    </div>
                </Modal.Body>
            </div>
        )

    }
}
